import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import SEO from '../components/seo';
import AnouncementBar from '../components/home/announcement-bar';
import IntroCta from '../components/home/intro-cta';
import Carousel from '../components/home/carousel';
import ZipPay from '../components/home/zippay';
import Love from '../components/home/love';
import FastestWay from '../components/home/fastest-way';
import Network from '../components/home/network';
import Team from '../components/home/meet-the-team';
import CustomerReviews from '../components/home/customer-reviews';

const CampaignPage = ({ data }) => {
  const { campaigns } = data;
  const { environment, publicKey } = data.zip;
  const { seo, structured_data } = campaigns;

  return (
    <Layout showSiteIndex={true} hideHeaderFooter={true}>
      <SEO
        title={seo.title}
        description={seo.title}
        canonical={seo.canonical}
        stucturedData={structured_data}
        ogTitle={seo.og.title}
        ogDescription={seo.og.description}
        ogImage={seo.og.image}
        ogType={seo.og.type}
        ogUrl={seo.og.url}
      />
      <AnouncementBar />
      <IntroCta
        title={campaigns.heading}
        intro={campaigns.description}
        link="/quote"
        linkText="Get free quotes"
      />
      <Carousel />
      {environment !== '' && publicKey !== '' && (
        <ZipPay />
      )}
      <Love />
      <FastestWay />
      <Network />
      <Team />
      <CustomerReviews />
      {environment !== '' && publicKey !== '' && (
        <ZipPay />
      )}
    </Layout>
  );
};

export const query = graphql`
  query CampaignQuery($url: String!) {
    campaigns(url: { eq: $url }) {
      url
      seo {
        title
        og {
          description
          image
          title
          type
          url
        }
        description
        canonical
      }
      name
      heading
      description
    }
    zip {
      environment
      publicKey
    }
  }
`;

CampaignPage.propTypes = {
  data: PropTypes.shape().isRequired,
  pageContext: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default CampaignPage;
